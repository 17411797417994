import React from 'react';
import './App.css';  // Customize your CSS

function App() {
  return (
    <div className="coming-soon">
      <img src={require('./image/Untitled design.png')} />
    </div>
  );
}

export default App;
